import { z } from "zod";

import { safeWith } from "./safeWith";

const errorMessageSchema = z.object({
  title: z.string(),
  description: z.string().optional(),
});

type ErrorMessage = z.infer<typeof errorMessageSchema>;

type MakeErrorMessageParams = ErrorMessage;

export const serializeErrorMessage = (
  params: MakeErrorMessageParams,
): string => {
  const result = errorMessageSchema.safeParse(params);

  if (result.success) {
    return JSON.stringify(params);
  }

  return "";
};

export const deserializeErrorMessage = (message: string) => {
  const parsedMessage = safeWith<Record<string, unknown>, () => string>({
    run: () => JSON.parse(message),
    callback: () => message,
  });

  const result = errorMessageSchema.safeParse(parsedMessage);

  if (result.success) {
    return result.data;
  }

  if (typeof parsedMessage === "string") {
    return parsedMessage;
  }

  return "";
};
