import { Button, Flex, Text } from "@daangn/carotene";

import { deserializeErrorMessage } from "~/utils/makeErrorMessage";

import * as css from "./ErrorPage.css";

type ErrorPageProps = {
  errorMessage: string;
  buttonLabel: string;
  isErrorMessageCode?: boolean;
  onButtonClick?: () => void;
};

export default function ErrorPage({
  buttonLabel,
  errorMessage,
  isErrorMessageCode,
  onButtonClick,
}: ErrorPageProps) {
  const error = deserializeErrorMessage(errorMessage);

  return (
    <Flex
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      flexGrow={1}
      gap={8}
      className={css.container}
    >
      {typeof error === "string" ? (
        <Text size="medium" color="neutral">
          {error}
        </Text>
      ) : (
        <Flex flexDirection="column" alignItems="center" gap={0.5}>
          <Text size="medium" color="neutral">
            {error.title}
          </Text>
          {error.description ? (
            <Text size="small" color="neutralSubtle">
              {error.description}
            </Text>
          ) : null}
        </Flex>
      )}
      {isErrorMessageCode ? (
        <p className={css.content}>{errorMessage}</p>
      ) : null}
      <Button onClick={onButtonClick} variant="neutral" size="large">
        {buttonLabel}
      </Button>
    </Flex>
  );
}
