type SafeWithParams<
  Result,
  Callback extends (...args: unknown[]) => unknown,
> = {
  run: (...args: unknown[]) => Result;
  callback: Callback;
};

export const safeWith = <
  Result = unknown,
  Callback extends (...args: unknown[]) => unknown = (
    ...args: unknown[]
  ) => unknown,
>({
  run,
  callback,
}: SafeWithParams<Result, Callback>) => {
  try {
    return run();
  } catch {
    return callback() as ReturnType<typeof callback>;
  }
};
